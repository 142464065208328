/* eslint-disable no-undef */
import { registerApplication, start } from 'single-spa'
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout'
import microfrontendLayout from './layout.html'
import Api from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import Mittens from 'mittens'
window.Auth = Auth

window.getCustomerId = () => {
  const href = window.location.href
  if (href.indexOf('vonixcc.com.br') === -1) return 'blackbox'
  let customerId = href.replace(window.location.protocol.concat('//'), '').split('.').shift().replace('-painel', '').trim()
  if (customerId === 'umbrella') customerId = 'blackbox'
  return customerId
}

const customer = window.getCustomerId()

window.Auth.configure({
  region: 'us-east-2',
  userPoolId: 'us-east-2_HJ49CQwvs',
  userPoolWebClientId: '3qd2p746kfgaj7ambv1pn86obd',
  authenticationFlowType: 'USER_SRP_AUTH',
  clientMetadata: { customer }
})

window.baseHost = customer ? `https://${customer}.api.vonixcc.com.br` : 'http://0.0.0.0:3003'
document.title = `${customer} - Vonix`

window.broker = new Mittens()

Api.configure({
  endpoints: [{
    name: 'api',
    endpoint: baseHost,
    region: 'us-east-2',
    custom_header: () => (
      window.getTokenJWT().then((token) => (
        token ? { Authorization: `Bearer ${token}` } : {}
      ))
    )
  }, {
    name: 'local',
    endpoint: 'http://0.0.0.0:3000',
    region: 'us-east-2',
    custom_header: () => (
      window.getTokenJWT().then((token) => (
        token ? { Authorization: `Bearer ${token}` } : {}
      ))
    )
  }]
})

window.getAuthSession = () => (
  getIdToken().then((idToken) => (
    idToken?.payload
  ))
)

window.getTokenJWT = () => (
  getIdToken().then((idToken) => (
    idToken?.jwtToken
  ))
)

window.signOut = () => (
  window.Auth.signOut()
)

window.getIdToken = () => (
  window.Auth.currentSession()
    .then((session) => session?.idToken)
    .catch(() => null)
)

window.api = {
  get: Api.get.bind(Api, 'api'),
  put: Api.put.bind(Api, 'api'),
  del: Api.del.bind(Api, 'api'),
  post: Api.post.bind(Api, 'api'),
  patch: Api.patch.bind(Api, 'api'),
  cancel: Api.cancel.bind(Api),
  getLocal: Api.get.bind(Api, 'local'),
  putLocal: Api.put.bind(Api, 'local'),
  postLocal: Api.post.bind(Api, 'local')
}

const routes = constructRoutes(microfrontendLayout)
const applications = constructApplications({
  routes,
  loadApp ({ name }) {
    return System.import(name)
  }
})
const layoutEngine = constructLayoutEngine({ routes, applications })

applications.forEach(registerApplication)
layoutEngine.activate()
start()
